import React, { useState } from "react";
import { motion } from "framer-motion";
import GitHubIcon from '@mui/icons-material/GitHub';
import LaunchIcon from '@mui/icons-material/Launch';
import CodeIcon from '@mui/icons-material/Code';
import mock01 from '../assets/images/mock01.png';
import mock02 from '../assets/images/mock02.png';
import mock03 from '../assets/images/mock03.png';
import mock04 from '../assets/images/mock04.png';
import mock05 from '../assets/images/mock05.png';
import mock06 from '../assets/images/mock06.png';
import mock07 from '../assets/images/mock07.png';
import mock08 from '../assets/images/mock08.png';
import mock09 from '../assets/images/mock09.png';
import mock10 from '../assets/images/mock10.png';
import '../assets/styles/Project.scss';

// Define project data
const projects = [
  {
    id: 1,
    title: "Handcraftednote",
    description: "Web application built with Angular and Firebase, featuring Stripe Payment Gateway, Authentication, Analytics, Google Cloud Functions, and Admin Panel.",
    image: mock10,
    techStack: ["Angular", "Firebase", "Stripe", "Authentication", "Cloud Functions"],
    link: "https://www.handcraftednote.com/",
    github: "",
    featured: true
  },
  {
    id: 2,
    title: "ITNG Conference Platform",
    description: "Complete rebuild of a legacy PHP website to a modern Angular application, featuring Firebase Realtime Database, Storage, and PayPal integration.",
    image: mock09,
    techStack: ["Angular", "Firebase", "PayPal", "Realtime Database", "Cloud Storage"],
    link: "https://itng.info",
    github: "",
    featured: true
  },
  {
    id: 3,
    title: "Hobby-it Mobile App",
    description: "Flutter-based mobile application that recommends new activities, helping users explore hobbies and build skills. Features include saved activities, notes, and calendar integration.",
    image: mock08,
    techStack: ["Flutter", "Firebase", "Mobile Development", "UI/UX Design"],
    link: "https://play.google.com/store/apps/details?id=com.hobby_it.i_am_bored",
    github: ""
  },
  {
    id: 4,
    title: "SeARch AR Application",
    description: "Android application that recognizes printed text and performs digital actions like copy and text-to-speech. Features real-time search highlighting and was presented at CES 2020.",
    image: mock07,
    techStack: ["Android", "AR", "OCR", "Text-to-Speech", "Computer Vision"],
    link: "https://drive.google.com/file/d/1DnLQqNAa0FbJHk9VYdycZ9vR7kqJNGD7/view",
    github: ""
  }
];

function Project() {
  const [hoveredProject, setHoveredProject] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState<string>("all");

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.2
        }
    }
  };

  const itemVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 100, damping: 15 }
    }
  };
  
  const filteredProjects = activeTab === "all" 
    ? projects 
    : activeTab === "featured" 
      ? projects.filter(project => project.featured) 
      : projects.filter(project => project.techStack.includes(activeTab));

  return (
    <div className="projects-container" id="projects">
      <motion.div
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        className="projects-content"
      >
        <motion.div className="section-header" variants={itemVariants}>
          <span className="section-subtitle">My Recent Work</span>
          <h1>Featured Projects</h1>
          <div className="underline"></div>
        </motion.div>
        
        <motion.div className="project-filters" variants={itemVariants}>
          <button 
            className={`filter-btn ${activeTab === "all" ? "active" : ""}`}
            onClick={() => setActiveTab("all")}
          >
            All Projects
          </button>
          <button 
            className={`filter-btn ${activeTab === "featured" ? "active" : ""}`}
            onClick={() => setActiveTab("featured")}
          >
            Featured
          </button>
          <button 
            className={`filter-btn ${activeTab === "Angular" ? "active" : ""}`}
            onClick={() => setActiveTab("Angular")}
          >
            Angular
          </button>
          <button 
            className={`filter-btn ${activeTab === "Firebase" ? "active" : ""}`}
            onClick={() => setActiveTab("Firebase")}
          >
            Firebase
          </button>
          <button 
            className={`filter-btn ${activeTab === "Mobile Development" ? "active" : ""}`}
            onClick={() => setActiveTab("Mobile Development")}
          >
            Mobile
          </button>
        </motion.div>

        <motion.div 
          className="projects-grid"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          key={activeTab} // This will re-render the grid with animations when tab changes
        >
          {filteredProjects.map((project, index) => (
            <motion.div 
              className="project-card"
              key={project.id}
              variants={itemVariants}
              onHoverStart={() => setHoveredProject(project.id)}
              onHoverEnd={() => setHoveredProject(null)}
              whileHover={{ y: -10 }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="project-image-container">
                <img 
                  src={project.image} 
                  alt={project.title} 
                  className={`project-image ${hoveredProject === project.id ? 'hovered' : ''}`}
                />
                <div className={`project-overlay ${hoveredProject === project.id ? 'visible' : ''}`}>
                  <div className="project-links">
                    {project.github && (
                      <motion.a 
                        href={project.github} 
                        target="_blank" 
                        rel="noreferrer" 
                        className="project-link github"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <GitHubIcon />
                      </motion.a>
                    )}
                    <motion.a 
                      href={project.link} 
                      target="_blank" 
                      rel="noreferrer" 
                      className="project-link live"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <LaunchIcon />
                    </motion.a>
                  </div>
                </div>
                
                {project.featured && (
                  <div className="featured-badge">
                    <span>Featured</span>
                  </div>
                )}
              </div>
              
              <div className="project-info">
                <div className="project-header">
                  <CodeIcon className="project-icon" />
                  <h3 className="project-title">{project.title}</h3>
                </div>
                <p className="project-description">{project.description}</p>
                
                <div className="project-tech">
                  {project.techStack.map((tech, i) => (
                    <span key={i} className="tech-tag">{tech}</span>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
        
        {filteredProjects.length === 0 && (
          <motion.div 
            className="no-projects"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <p>No projects found with the selected filter.</p>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
}

export default Project;