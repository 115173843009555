// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import "firebase/compat/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAwFRThYEDi3xJeQG7Dy9ms1NUzsIPrXyo",
  authDomain: "prabhas-info.firebaseapp.com",
  projectId: "prabhas-info",
  storageBucket: "prabhas-info.firebasestorage.app",
  messagingSenderId: "1051206950712",
  appId: "1:1051206950712:web:012390254926b5c0af2bcd",
  measurementId: "G-0HWP3QLZT5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { analytics, logEvent };
