import React, {useState, useEffect} from "react";
import {
  Main,
  Timeline,
  Expertise,
  Project,
  Contact,
  Navigation,
  Footer,
  HeroSection
} from "./components";
import LoadingScreen from './components/LoadingScreen';
import CustomCursor from './components/CustomCursor';
import './index.scss';

function App() {
    const [mode, setMode] = useState<string>('dark');
    const [loading, setLoading] = useState<boolean>(true);

    const handleModeChange = () => {
        setMode(mode === 'dark' ? 'light' : 'dark');
        // Save preference to localStorage
        localStorage.setItem('themeMode', mode === 'dark' ? 'light' : 'dark');
    }

    useEffect(() => {
        // Check for saved theme preference
        const savedTheme = localStorage.getItem('themeMode');
        if (savedTheme) {
            setMode(savedTheme);
        }

        // Simulate loading screen
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1800);

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return <LoadingScreen mode={mode} />;
    }

    return (
        <div className={`main-container ${mode === 'dark' ? 'dark-mode' : 'light-mode'}`}>
            <Navigation parentToChild={{mode}} modeChange={handleModeChange}/>
            <div className="content-wrapper">
                <HeroSection />
                <Main />
                <Expertise />
                <Timeline />
                <Project />
                <Contact />
            </div>
            <Footer />
        </div>
    );
}

export default App;