import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { motion } from 'framer-motion';
import 'react-vertical-timeline-component/style.min.css';
import '../assets/styles/Timeline.scss';

// Define timeline items
const timelineItems = [
  {
    id: 1,
    title: "Full Stack Software Engineer",
    company: "Transact Technologies",
    location: "Las Vegas, NV",
    date: "2021 - present",
    description: [
      "Angular Development",
      "Azure Cloud Services",
      "REST APIs in .NET Core",
      "Automation in Cypress",
      "Self Maintained ELK Stack"
    ],
    type: "work",
  },
  {
    id: 2,
    title: "Software Engineer Intern",
    company: "Agilysys Inc.",
    location: "Las Vegas, NV",
    date: "2019 - 2020",
    description: [
      "JavaScript + CSS + HTML, ElectronJS",
      "Desktop App Development",
      "REST API Development",
      "SQL Server, Postman",
      "Collaboration with 23rd Party Vendors"
    ],
    type: "work",
  },
  {
    id: 3,
    title: "Undergraduate Research Assistant",
    company: "University of Nevada Las Vegas",
    location: "Las Vegas, NV",
    date: "2018 - 2019",
    description: [
      "Website Maintenance written in PHP",
      "Research Work in Cybersecurity, ML, etc"
    ],
    type: "work",
  },
  {
    id: 4,
    title: "Bachelor of Science in Computer Science",
    company: "University of Nevada Las Vegas",
    location: "Las Vegas, NV",
    date: "2016 - 2020",
    description: ["Minor in Mathematics"],
    type: "education",
  },
];

function Timeline() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 100, damping: 15 }
    }
  };

  return (
    <div id="history">
      <motion.div 
        className="timeline-container"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
      >
        <motion.div className="section-header" variants={itemVariants}>
          <span className="section-subtitle">My Journey</span>
          <h1>Career History</h1>
          <div className="underline"></div>
        </motion.div>
        
        <VerticalTimeline className="custom-timeline" animate={true}>
          {timelineItems.map((item) => (
            <VerticalTimelineElement
              key={item.id}
              className={`vertical-timeline-element--${item.type}`}
              contentStyle={{ 
                background: 'var(--card-light)', 
                color: 'var(--text-dark)',
                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.05)',
                borderRadius: 'var(--border-radius-lg)',
                padding: '2rem'
              }}
              contentArrowStyle={{ borderRight: '7px solid var(--card-light)' }}
              date={item.date}
              iconStyle={{ 
                background: 'var(--primary-color)', 
                color: '#fff',
                boxShadow: '0 0 0 4px var(--primary-light), inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05)'
              }}
              icon={<FontAwesomeIcon icon={item.type === 'work' ? faBriefcase : faGraduationCap} />}
            >
              <div className="timeline-content">
                <h3 className="vertical-timeline-element-title">{item.title}</h3>
                <h4 className="vertical-timeline-element-company">{item.company}</h4>
                <h5 className="vertical-timeline-element-location">{item.location}</h5>
                
                <div className="timeline-description">
                  <ul>
                    {item.description.map((desc, index) => (
                      <li key={index}>{desc}</li>
                    ))}
                  </ul>
                </div>
                
                {item.type === 'work' && (
                  <div className="timeline-badge">
                    <span>Experience</span>
                  </div>
                )}
                
                {item.type === 'education' && (
                  <div className="timeline-badge education">
                    <span>Education</span>
                  </div>
                )}
              </div>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </motion.div>
    </div>
  );
}

export default Timeline;