import React from "react";
import { motion } from "framer-motion";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import '../assets/styles/Footer.scss';

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 100 }
    }
  };

  return (
    <footer>
      <motion.div 
        className="footer-content"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
      >
        <motion.div className="footer-logo" variants={itemVariants}>
          <span>Prabhas Kumra</span>
        </motion.div>
        
        <motion.div className="footer-nav" variants={itemVariants}>
          <a href="#main">Home</a>
          <a href="#expertise">Expertise</a>
          <a href="#history">History</a>
          <a href="#projects">Projects</a>
          <a href="#contact">Contact</a>
        </motion.div>
        
        <motion.div className="social-links" variants={itemVariants}>
          <a href="https://github.com/prabhaskumra" target="_blank" rel="noreferrer" aria-label="GitHub">
            <GitHubIcon/>
          </a>
          <a href="https://www.linkedin.com/in/prabhaskumra/" target="_blank" rel="noreferrer" aria-label="LinkedIn">
            <LinkedInIcon/>
          </a>
          <a href="https://stackoverflow.com/users/11360806/prabhas-kumra" target="_blank" rel="noreferrer" aria-label="Stack Overflow">
            <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
              <path d="M17.36 20.95v-6.7h2.24l.33-2.6h-2.57v-1.67c0-.75.2-1.27 1.3-1.27h1.4V6.4a18.5 18.5 0 00-2.02-.1c-2 0-3.37 1.22-3.37 3.45v1.92H12.4v2.6h2.26v6.7h2.7zM4.98 13.12l.79-.55s.27.38.5.62c.23.23.47.32.47.32l-.75.55s-.23-.29-.45-.46c-.22-.17-.56-.48-.56-.48zm.45-1.38l.73-.73s.28.38.52.62c.23.23.5.34.5.34l-.76.72s-.28-.33-.5-.5c-.22-.16-.49-.45-.49-.45zm2.21-1.57l.73-.73s.28.38.52.62c.24.24.5.35.5.35l-.76.72s-.28-.33-.49-.5c-.22-.16-.5-.46-.5-.46zm1.64-.78l.73-.73s.29.38.53.61c.23.24.49.35.49.35l-.76.73s-.28-.34-.5-.5c-.22-.17-.49-.46-.49-.46zm2.8 11.13v-5.37h1.8V20a.52.52 0 01-.52.52h-6.4a.52.52 0 01-.53-.52v-5.37h1.81v5.41h3.84v-.04zm-4.13-8.51l.79-.52s.28.37.47.59c.2.21.5.38.5.38l-.76.56s-.28-.46-.48-.66c-.2-.2-.52-.35-.52-.35zm2.71-1.69l5.07-3.56.94 1.3-5.07 3.57-.94-1.31zm.56 2.27l5.1-3.34.9 1.33-5.1 3.34-.9-1.33zM3.25 17.14h10.46v1.78H3.25v-1.78zm10.54-5.67l5.3-2.5.75 1.57-5.3 2.5-.75-1.57zM3.58 13.6h5.32v1.77H3.58V13.6zm10.63 2.1l5.57-1.01.32 1.75-5.57 1-.32-1.74z" />
            </svg>
          </a>
        </motion.div>
        
        <motion.div className="copyright" variants={itemVariants}>
          <p>© {new Date().getFullYear()} Prabhas Kumra. All rights reserved.</p>
        </motion.div>
        
        <motion.div 
          className="scroll-to-top"
          variants={itemVariants}
          whileHover={{ y: -5 }}
          onClick={scrollToTop}
        >
          <KeyboardArrowUpIcon />
        </motion.div>
      </motion.div>
    </footer>
  );
}

export default Footer;