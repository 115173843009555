import React, { useEffect, useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import LightModeIcon from '@mui/icons-material/LightMode';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { motion } from 'framer-motion';
import '../assets/styles/Navigation.scss';

const drawerWidth = 240;
const navItems = [
  ['Home', 'hero-section'], 
  ['Expertise', 'expertise'], 
  ['History', 'history'], 
  ['Projects', 'projects'], 
  ['Contact', 'contact']
];

function Navigation({parentToChild, modeChange}: any) {
  const {mode} = parentToChild;
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<string>('hero-section');

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Handle navbar background change on scroll
      const navbar = document.getElementById("navigation");
      if (navbar) {
        const scrolled = window.scrollY > 100;
        setScrolled(scrolled);
      }

      // Handle active section highlighting
      const sections = navItems.map(item => item[1]);
      const scrollPosition = window.scrollY + 200;

      sections.forEach(section => {
        const element = document.getElementById(section);
        if (element) {
          const offsetTop = element.offsetTop;
          const height = element.clientHeight;
          
          if (scrollPosition >= offsetTop && scrollPosition < offsetTop + height) {
            setActiveSection(section);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (section: string) => {
    const element = document.getElementById(section);
    if (element) {
      setMobileOpen(false);
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const drawer = (
    <Box className={`navigation-bar-responsive ${mode === 'dark' ? 'dark-drawer' : 'light-drawer'}`} onClick={handleDrawerToggle}>
      <div className="mobile-menu-top">
        <span className="logo">Prabhas Kumra</span>
      </div>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item[0]} disablePadding>
            <ListItemButton 
              sx={{ textAlign: 'center' }} 
              onClick={() => scrollToSection(item[1])}
              className={activeSection === item[1] ? 'active-section' : ''}
            >
              <ListItemText primary={item[0]} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <div className="drawer-toggle-theme">
        <IconButton onClick={(e) => {
          e.stopPropagation();
          modeChange();
        }}>
          {mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
        </IconButton>
      </div>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar 
        component="nav" 
        id="navigation" 
        className={`navbar-fixed-top ${scrolled ? 'scrolled' : ''} ${mode === 'dark' ? 'dark-nav' : 'light-nav'} ${activeSection === 'hero-section' ? 'at-home' : ''}`}
        elevation={scrolled ? 4 : 0}
      >
        <Toolbar className='navigation-bar'>
          <motion.div 
            className="logo-container"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <span className="logo">Prabhas Kumra</span>
          </motion.div>

          <div className="nav-controls">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' } }}
              className="menu-button"
            >
              <MenuIcon />
            </IconButton>
            
            <Box sx={{ display: { xs: 'none', sm: 'flex' } }} className="nav-items">
              {navItems.map((item, index) => (
                <motion.div
                  key={item[0]}
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1, duration: 0.5 }}
                >
                  <Button 
                    onClick={() => scrollToSection(item[1])} 
                    className={activeSection === item[1] ? 'active-nav-link' : ''}
                  >
                    {item[0]}
                    {activeSection === item[1] && (
                      <motion.div 
                        className="active-indicator" 
                        layoutId="activeIndicator"
                        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                      />
                    )}
                  </Button>
                </motion.div>
              ))}
            </Box>
            
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              className="theme-toggle"
            >
              <IconButton onClick={modeChange} className="theme-button">
                {mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
              </IconButton>
            </motion.div>
          </div>
        </Toolbar>
      </AppBar>
      
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default Navigation;