import React, { useEffect, useState } from 'react';
import '../assets/styles/LoadingScreen.scss';

interface LoadingScreenProps {
  mode: string;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ mode }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 5;
        if (newProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return newProgress;
      });
    }, 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`loading-screen ${mode === 'dark' ? 'dark-mode' : 'light-mode'}`}>
      <div className="loader-content">
        <div className="loader-logo">PK</div>
        <div className="loader-bar-container">
          <div 
            className="loader-bar" 
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <div className="loader-text">Loading Portfolio...</div>
      </div>
    </div>
  );
};

export default LoadingScreen;