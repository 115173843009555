import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import '../assets/styles/Contact.scss';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// Import Firebase functionality
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { getApp } from 'firebase/app';

// Use the existing Firebase app instance
const app = getApp();
const db = getFirestore(app);
function Contact() {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const [nameError, setNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<boolean>(false);
  
  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const form = useRef();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      }
    }
  };

  const itemVariants = {
    hidden: { y: 30, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 100, damping: 15 }
    }
  };

  const handleCloseSuccess = () => {
    setSubmitSuccess(false);
  };

  const handleCloseError = () => {
    setSubmitError(false);
  };

  const sendEmail = async (e: React.FormEvent) => {
    e.preventDefault();

    setNameError(name === '');
    setEmailError(email === '');
    setMessageError(message === '');

    if (name !== '' && email !== '' && message !== '') {
      setIsSubmitting(true);
      
      try {
        // Add data to Firestore
        await addDoc(collection(db, "contacts"), {
          name,
          email,
          message,
          createdAt: serverTimestamp()
        });
        
        setSubmitSuccess(true);
        setName('');
        setEmail('');
        setMessage('');
      } catch (error) {
        console.error("Error adding document: ", error);
        setSubmitError(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div id="contact">
      <motion.div 
        className="contact-container"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
      >
        <motion.div className="section-header" variants={itemVariants}>
          <span className="section-subtitle">Get In Touch</span>
          <h1>Contact Me</h1>
          <div className="underline"></div>
        </motion.div>

        <div className="contact-content">
          <motion.div 
            className="contact-info"
            variants={itemVariants}
          >
            <h2>Let's Connect</h2>
            <p>Got a project waiting to be realized? Let's collaborate and make it happen! Feel free to reach out through any of the channels below.</p>
            
            <div className="contact-methods">
              <motion.div 
                className="contact-method"
                variants={itemVariants}
                whileHover={{ scale: 1.05 }}
              >
                <div className="method-icon">
                  <GitHubIcon />
                </div>
                <div className="method-details">
                  <h3>GitHub</h3>
                  <p>github.com/prabhaskumra</p>
                </div>
              </motion.div>
              
              <motion.div 
                className="contact-method"
                variants={itemVariants}
                whileHover={{ scale: 1.05 }}
              >
                <div className="method-icon">
                  <LinkedInIcon />
                </div>
                <div className="method-details">
                  <h3>LinkedIn</h3>
                  <p>linkedin.com/in/prabhaskumra</p>
                </div>
              </motion.div>
              
              <motion.div 
                className="contact-method"
                variants={itemVariants}
                whileHover={{ scale: 1.05 }}
              >
                <div className="method-icon">
                  <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
                    <path d="M17.36 20.95v-6.7h2.24l.33-2.6h-2.57v-1.67c0-.75.2-1.27 1.3-1.27h1.4V6.4a18.5 18.5 0 00-2.02-.1c-2 0-3.37 1.22-3.37 3.45v1.92H12.4v2.6h2.26v6.7h2.7zM4.98 13.12l.79-.55s.27.38.5.62c.23.23.47.32.47.32l-.75.55s-.23-.29-.45-.46c-.22-.17-.56-.48-.56-.48zm.45-1.38l.73-.73s.28.38.52.62c.23.23.5.34.5.34l-.76.72s-.28-.33-.5-.5c-.22-.16-.49-.45-.49-.45zm2.21-1.57l.73-.73s.28.38.52.62c.24.24.5.35.5.35l-.76.72s-.28-.33-.49-.5c-.22-.16-.5-.46-.5-.46zm1.64-.78l.73-.73s.29.38.53.61c.23.24.49.35.49.35l-.76.73s-.28-.34-.5-.5c-.22-.17-.49-.46-.49-.46zm2.8 11.13v-5.37h1.8V20a.52.52 0 01-.52.52h-6.4a.52.52 0 01-.53-.52v-5.37h1.81v5.41h3.84v-.04zm-4.13-8.51l.79-.52s.28.37.47.59c.2.21.5.38.5.38l-.76.56s-.28-.46-.48-.66c-.2-.2-.52-.35-.52-.35zm2.71-1.69l5.07-3.56.94 1.3-5.07 3.57-.94-1.31zm.56 2.27l5.1-3.34.9 1.33-5.1 3.34-.9-1.33zM3.25 17.14h10.46v1.78H3.25v-1.78zm10.54-5.67l5.3-2.5.75 1.57-5.3 2.5-.75-1.57zM3.58 13.6h5.32v1.77H3.58V13.6zm10.63 2.1l5.57-1.01.32 1.75-5.57 1-.32-1.74z" />
                  </svg>
                </div>
                <div className="method-details">
                  <h3>Stack Overflow</h3>
                  <p>https://stackoverflow.com/users/11360806/prabhas-kumra</p>
                </div>
              </motion.div>
            </div>
          </motion.div>
          
          <motion.div 
            className="contact-form-container"
            variants={itemVariants}
          >
            <Box
              ref={form}
              component="form"
              noValidate
              autoComplete="off"
              className="contact-form"
              onSubmit={sendEmail}
            >
              <div className="form-group">
                <TextField
                  required
                  id="name"
                  label="Your Name"
                  placeholder="What's your name?"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (e.target.value !== '') setNameError(false);
                  }}
                  error={nameError}
                  helperText={nameError ? "Please enter your name" : ""}
                  className="form-field"
                />
              </div>
              
              <div className="form-group">
                <TextField
                  required
                  id="email"
                  label="Email / Phone"
                  placeholder="How can I reach you?"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (e.target.value !== '') setEmailError(false);
                  }}
                  error={emailError}
                  helperText={emailError ? "Please enter your email or phone number" : ""}
                  className="form-field"
                />
              </div>
              
              <div className="form-group">
                <TextField
                  required
                  id="message"
                  label="Message"
                  placeholder="Send me any inquiries or questions"
                  multiline
                  rows={6}
                  variant="outlined"
                  fullWidth
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    if (e.target.value !== '') setMessageError(false);
                  }}
                  error={messageError}
                  helperText={messageError ? "Please enter a message" : ""}
                  className="form-field"
                />
              </div>
              
              <Button 
                variant="contained" 
                color="primary"
                endIcon={isSubmitting ? null : <SendIcon />}
                type="submit"
                disabled={isSubmitting}
                className="submit-button"
              >
                {isSubmitting ? 'Sending...' : 'Send Message'}
              </Button>
            </Box>
          </motion.div>
        </div>
        
        <Snackbar open={submitSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
          <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
            Your message has been sent successfully! I'll get back to you soon.
          </Alert>
        </Snackbar>
        
        <Snackbar open={submitError} autoHideDuration={6000} onClose={handleCloseError}>
          <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
            Failed to send message. Please try again or contact me directly via email.
          </Alert>
        </Snackbar>
      </motion.div>
    </div>
  );
}

export default Contact;