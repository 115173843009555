import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faDocker, faPython } from '@fortawesome/free-brands-svg-icons';
import Chip from '@mui/material/Chip';
import { motion } from 'framer-motion';
import '../assets/styles/Expertise.scss';

const labelsFirst = [
    "Angular",
    "React",
    "Flutter",
    "NativeScript",
    "TypeScript",
    "JavaScript",
    "C#",
    "Dart",
    "HTML",
    "CSS/SCSS"
];

const labelsSecond = [
    "Azure DevOps",
    "Docker",
    "CI/CD",
    "Azure",
    "AWS",
    "Firebase",
    "Linux",
    "Elasticsearch",
    "Cypress",
    "Jest"
];

const labelsThird = [
    "Flask",
    "Python",
    "SQL",
    "PostgreSQL",
    "REST APIs",
    "GraphQL",
    "Node.js",
    ".NET Core",
    "MongoDB"
];

function Expertise() {
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { y: 50, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: { type: 'spring', stiffness: 100, damping: 15 }
        }
    };

    const chipVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: (i: number) => ({
            opacity: 1,
            scale: 1,
            transition: { delay: i * 0.05 }
        })
    };

    return (
        <div className="container" id="expertise">
            <motion.div 
                className="skills-container"
                variants={containerVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
            >
                <motion.div className="section-header" variants={itemVariants}>
                    <span className="section-subtitle">What I Do</span>
                    <h1>Expertise</h1>
                    <div className="underline"></div>
                </motion.div>

                <motion.div className="skills-grid" variants={containerVariants}>
                    <motion.div className="skill" variants={itemVariants}>
                        <div className="skill-icon">
                            <FontAwesomeIcon icon={faReact} />
                        </div>
                        <h3>Front-End Development</h3>
                        <p>I create responsive and interactive user interfaces that provide exceptional user experiences, using modern frameworks and best practices.</p>
                        <div className="flex-chips">
                            <span className="chip-title">Tech stack:</span>
                            <div className="chips-container">
                                {labelsFirst.map((label, index) => (
                                    <motion.div
                                        key={index}
                                        custom={index}
                                        variants={chipVariants}
                                    >
                                        <Chip className='chip' label={label} />
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    </motion.div>

                    <motion.div className="skill" variants={itemVariants}>
                        <div className="skill-icon">
                            <FontAwesomeIcon icon={faPython} />
                        </div>
                        <h3>Back-End Development</h3>
                        <p>I build robust server-side applications, APIs, and database architectures that power complex web applications with scalability in mind.</p>
                        <div className="flex-chips">
                            <span className="chip-title">Tech stack:</span>
                            <div className="chips-container">
                                {labelsThird.map((label, index) => (
                                    <motion.div
                                        key={index}
                                        custom={index}
                                        variants={chipVariants}
                                    >
                                        <Chip className='chip' label={label} />
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    </motion.div>

                    <motion.div className="skill" variants={itemVariants}>
                        <div className="skill-icon">
                            <FontAwesomeIcon icon={faDocker} />
                        </div>
                        <h3>DevOps & Automation</h3>
                        <p>I streamline development workflows by implementing CI/CD pipelines, automated testing, and deployment strategies for efficient product delivery.</p>
                        <div className="flex-chips">
                            <span className="chip-title">Tech stack:</span>
                            <div className="chips-container">
                                {labelsSecond.map((label, index) => (
                                    <motion.div
                                        key={index}
                                        custom={index}
                                        variants={chipVariants}
                                    >
                                        <Chip className='chip' label={label} />
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            </motion.div>
        </div>
    );
}

export default Expertise;